export const logos = [
    {
      'nombre': 'LA SEGUNDA COOP.SEG.GRALES.',
      'className': 'lasegunda',
      'url': 'https://www.segurobici.com.ar/images/logos/lasegunda.svg'
    }, 
    {
      'nombre': 'LA SEGUNDA',
      'className': 'lasegunda',
      'url': 'https://www.segurobici.com.ar/images/logos/lasegunda.svg'
    }, 
    {
      'nombre': 'EXPERTA ART',
      'className': 'experta',
      'url': 'https://auto.seguroweb.com.ar/images/logos/experta-seguros.svg'
    }, 
    {
      'nombre': 'EXPERTA SEGUROS S.A.',
      'className': 'experta',
      'url': 'https://auto.seguroweb.com.ar/images/logos/experta-seguros.svg'
    }, 
    {
      'nombre': 'HDI SEGUROS S.A.',
      'className': 'hdi',
      'url': 'https://auto.seguroweb.com.ar/images/logos/hdiSeguros.svg'
    },
    {
      'nombre': 'FEDERACION PATRONAL',
      'className': 'fedpat',
      'url': 'https://comercio.seguroweb.com.ar/imagenes/logos/federacionpatronal.svg'
    },
    {
      'nombre': 'PRUDENCIA-BICICLETAS',
      'className': 'prudencia',
      'url': 'https://files.gyssrl.ar/api/v1/storage/image/5789aea3-5221-4097-8446-36f84cce9954'
    },
    {
      'nombre': 'ATM',
      'className': 'atm',
      'url': 'https://auto.seguroweb.com.ar/images/logos/atmseguros.svg'
    },
    {
      'nombre': 'NACION SEGUROS S.A.',
      'className': 'nacion',
      'url': 'https://gys.com.ar/images/logos/aseguradoras/nacionSeguros.svg'
    },
    {
      'nombre': 'ORBIS CIA.ARG.DE SEG.',
      'className': 'orbis',
      'url': 'https://gys.com.ar/images/logos/aseguradoras/segurosOrbis.svg'
    },,
    {
      'nombre': 'PROVINCIA SEGUROS S.A.',
      'className': 'provincia',
      'url': 'https://auto.seguroweb.com.ar/images/logos/provincia-seguros.svg'
    },
];